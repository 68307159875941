@include fontFace('FontAwesome');
@include fontFace2('FuturaDemiC',400);
@include fontFace2('GothamPro',400);
@include fontFace2('GothamPro',900);
@include fontFace2('GothamPro',500);
@include fontFace2('NovitoNova',100);
@include fontFace2('NovitoNova',400);



$width: 1200px;

$tablet: 'all and (max-width: #{$width})';
$mobile: 'all and (max-width: 768px)';


@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
*:focus{
  outline: none!important;
}
body{
  font-family:'GothamPro';
  color: #333333;
  b{
    font-weight: 900;
  }
}
.futura{
  font-family:'FuturaDemiC';
  font-weight: 400;
}
.nova{
  font-family:'NovitoNova';
  font-weight: 400;
  &.light{
    font-weight: 100;
  }
}
.ttu{
  text-transform: uppercase;
}

.row{
  &.between{
    justify-content: space-between;
    @media(max-width: 991px){
     &.between-around{
       justify-content: space-around;
     }
    }
  }
  &.around{
    justify-content: space-around;
  }
}

.table-wrapper{
  display: table;
  width: 100%;
  height: 100%;
}
.cell-wrapper{
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.avc{
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.ahc{
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.ul{
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.anim_all_05{
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
h2{
  color: #9dc02e;
  font-size: 72px;
  line-height: 1;
  @extend .nova;
  text-transform: uppercase;
  text-align: center;
}

.second_box{
 @media(min-width: 993px) {
   padding-left: 0;
   padding-right: 0;
 }

  text-align: center;
  position: relative;
  font-size: 15px;
  min-width: 200px;
  &:after{
    bottom: 0;
    width: 100%;
    height: 90%;
    left: 0;
    border: 1px dashed #485d75;
    content: '';
    position: absolute;
    border-radius: 50%;
    z-index:-1;
  }
  .img_box{
    position: relative;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    background: #48aad3;
    margin: 30px auto;
    &:after{
      background-image: url(../img/sprite.png);
      background-position: -512px 0;
      width: 165px;
      height: 187px;
      position: absolute;
      content: '';
      bottom: -5px;
      left: -10px;
    }
    .img{
      position: absolute;
      left: 0;
      right:0;
      top: 0;
      bottom: 0;
      margin:auto;
      z-index:2;
    }
  }
  p{
    color: #ffffff;
  }
  .nova{
    font-size: 36px;
    color: #9dc02e;
    line-height: 1.2;
    margin: 0;

  }
  &:nth-child(1){
    .img{
      @include sprite($sprite-flask);
    }
  }
  &:nth-child(2){
    .img{
      @include sprite($sprite-pregnant);
    }
    .img_box{
      &:after{
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        left: 0;
        bottom: -7px;
      }
    }

  }
  &:nth-child(3){
    .img{
      @include sprite($sprite-speed);
    }
    .img_box{
      &:after{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        bottom: -16px;
        left: 5px;
      }
    }


  }
  &:nth-child(4){
    .img{
      @include sprite($sprite-salad);
    }
    .img_box{
      &:after{
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        bottom: -27px;
        left: 3px;
      }
    }

  }
  &:nth-child(5){
    .img{
      @include sprite($sprite-slim-body-with-timer);
    }
    .img_box{
      &:after{
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        bottom: -35px;
        left: -11px;
      }
    }

  }
}
.fourth_box{
  margin: 20px 0;
  font-size: 18px;
  .img {
    @media(max-width: 767px){
      text-align: center;
      margin-bottom: 20px;
    }
    @media(min-width: 768px) {
      float: left;
      margin-right: 20px;
    }
  }
  img{
    border-radius: 50%;
    -webkit-box-shadow: 1px 0px 18px 0px rgba(0,0,0,0.65);
    -moz-box-shadow: 1px 0px 18px 0px rgba(0,0,0,0.65);
    box-shadow: 1px 0px 18px 0px rgba(0,0,0,0.65);
  }
  .text{
    overflow: hidden;

    @media(min-width: 768px) {
      height: 100%;
    }
    p{
      margin-bottom: 0;
      color: #ffffff;
      font-weight: 500;

    }
    span{
      color: #9dc02e;
    }
  }

}
.fifth_box{
  flex: 0 1 20%;
  flex-basis: 20%;
  text-align: center;
  .img_wrapper{
    border: 1px dashed #73bcd9;
    border-radius: 50%;
    padding: 8px;
    width: 185px;
    height: 185px;
    margin: 0 auto 20px;
    .img{
      border-radius: 50%;
      background: #063e8d;
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      img{
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
      }
    }
  }
  p{
    font-size: 18px;
  }
  .title{
    text-transform: uppercase;
    color: #063e8d;
    font-size: 42px;
    line-height: 1.1;
  }

}
.sert_wrapper{
  width: 190px;
  height: 240px;
  border: 6px solid #0a0a0a;
  -webkit-box-shadow: 1px 0px 18px 0px rgba(0,0,0,0.65);
  -moz-box-shadow: 1px 0px 18px 0px rgba(0,0,0,0.65);
  box-shadow: 1px 0px 18px 0px rgba(0,0,0,0.65);
  display: inline-block;
  vertical-align: top;
  margin-right: 35px;
  &:last-child{
    @media(min-width: 768px){
      margin-right: 0;
    }
  }
  .sert_inner{
    border-width: 10px;
    border-top-color: #e2e2e2;
    border-left-color: #cbcbcb;
    border-right-color: #f1f1f1;
    border-bottom-color:#e2e2e2;
    border-style: solid;
    display: block;
    width: 100%;
    height: 100%;
  }
  .sert{
    border-style: solid;
    display: block;
    width: 100%;
    height: 100%;
    border-left:2px #c2c2c2 solid;
    border-right:1px solid #e8e8e8;
    border-top:1px solid #d9d9d9;
    border-bottom:2px solid #dedede;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media(max-width: 767px){
    display: block;
    margin: 10px auto 0;
  }
}



.first{
  background: url("../img/big_bg.png") top center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
 // padding-top: 120px;
  max-width: 100%;
  overflow: hidden;
  .container{
    @media(max-width: 575px){
      .between{
        padding-left: 15px;
        padding-right: 15px;
      }

    }
  }
}
.first_content{
  background: url("../img/slider_bg.png")top center no-repeat;
  padding-bottom: 80px;
  -webkit-background-size: cover;
  background-size: cover;
  .img{
    img{
     @media(min-width: 1200px){
       margin-left: -195px;
     }
      @media(max-width: 1199px){
        max-width: 100%;
      }
    }
  }
  .text{
    p{
      font-size: 36px;
      color: #063e8d;
      line-height: 1.1;
      @extend .futura;
      span{
        @extend .nova;
        color: #9dc02e;
        font-size: 95px;
        display: block;
      }
    }
    .btn_wrapper{
      text-align: left;
    }
    @media(max-width: 768px){
      text-align: center;
      .btn_wrapper{
        text-align: center;
      }
    }
  }
}
header{
  //position: absolute;
  //width: 100%;
  //left: 0;
  //top: 3px;
  margin-top: 3px;
  margin-bottom: 20px;
  .header_logo{
    flex:0 1 605px;
    flex-basis: 605px;
    a{
      float: left;
      margin-right: 25px;
    }
    p{
      font-size: 18px;
      color: #063e8d;
      margin-top: 40px;
      margin-bottom: 0;
      line-height: 1;
      overflow: hidden;
    }
  }
  .header_contacts{
    flex: 0 1 auto;
    flex-basis: auto;
    text-align: right;
    overflow: hidden;
    .phone{
      font-weight: 700;
      font-size: 33px;
      color: #063e8d;
      text-decoration: none;
      float: right;
    }
    .call_me{
      float: right;
      clear: both;
      color: #063e8d;
      text-transform: uppercase;
      font-size: 14px;
      border-bottom:1px dashed #063e8d;
      text-decoration: none;
      &:hover{
    //border-bottom-color:transparent;
        color: rgb(223, 171, 63);

      }
    }
    .social{
      padding-top: 10px;
      clear: both;
      text-align: right;
      a{
        display: inline-block;
        vertical-align: top;
        width: 42px;
        height: 42px;border-radius: 50%;
        color: #ffffff;
        text-align: center;
        &>*{
          line-height: 42px;
        }
        margin-left: 7px;
        &.vk{
          background: #6996c7;
        }
        &.insta{
          background: #7a6b67;
        }
      }
    }

  }
  @media(max-width: 991px){
    .header_logo{
      flex:0 1 173px;
      flex-basis: 173px;
      p{
        display: none;
      }
      a{
        margin-right: 0;
      }
    }

  }
  @media(max-width: 370px){
   .header_logo,.header_contacts{
     text-align: center;
     flex:0 1 100%;
     flex-basis: 100%;
   }
    .header_logo{
      a{
        float: none;
        display: inline-block;
        margin: 0 0 20px 0;
      }
    }
    .header_contacts{
      .phone,.call_me{
        display: inline-block;
        float: none;
        min-width: 180px;
      }
      .social{
        text-align: center;
        a{
          margin: 0 5px;
        }
      }
    }
  }
}
//second

.second{
  padding-top: 30px;
  padding-bottom: 150px;
}

.third_photo{
  img{
    margin-left: -60px;
  }
  @media(max-width: 1199px){
    text-align: center;
    img{
      margin: 0;
      max-width: 100%;
    }
  }
}
.third_content{
  padding-top: 40px;
  text-align: center;
 @media(min-width: 1200px){
   padding-top: 125px;
   text-align: left;
 }
  p{
    margin-bottom: 0;
  }
  .nova{
    color: #9dc02e;
    font-size: 72px;
    line-height: 1;
    text-transform: uppercase;
    display: block;
  }
  .third_sert{
    margin-top: 30px;
  }
}
.fourth{
  background: url("../img/big_bg.png") top center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
  padding-top: 80px;
  padding-bottom: 130px;
  z-index:0;
  margin-top: -40px;
  .factors_header{
    h2{
      margin-top: 0;
    }
    p{
      max-width: 960px;
      margin: 0 auto;
      text-align: center;
      text-transform: uppercase;
      font-size: 21px;
      @extend .futura;
    }
    margin-bottom: 40px;
    &+h2{
      max-width: 1050px;
      margin: 0 auto 30px;
    }
  }
  @media(min-width: 769px){
    background: url("../img/big_bg_2.png") top center no-repeat;
    .factors_header{
      margin-bottom: 375px;
    }
  }
}
footer{
  background: #214466;
  padding-top: 133px;
  margin-top: -75px;
  z-index:0;
  padding-bottom: 65px;
  position: relative;
  .footer_logo{
    flex:0 1 605px;
    flex-basis: 605px;
    a{
      float: left;
      margin-right: 25px;
    }
    text{
      overflow: hidden;
      padding-top: 10px;
    }
    p{
      font-size: 18px;
      color: #fff;
      margin-bottom: 0;
      line-height: 1;
      overflow: hidden;
      &+p{
        font-size: 15px;
        margin-top: 35px;
      }
    }
  }
  .footer_contacts{
    flex: 0 1 auto;
    flex-basis: auto;
    text-align: right;
    overflow: hidden;
    .phone{
      font-weight: 700;
      font-size: 33px;
      color: #fff;
      text-decoration: none;
      float: right;
    }
    .call_me{
      float: right;
      clear: both;
      color: #00a1df;
      text-transform: uppercase;
      font-size: 14px;
      border-bottom:1px dashed #00a1df;
      text-decoration: none;
      &:hover{
        //border-bottom-color:transparent;
        color: rgb(223, 171, 63);

      }
    }
    .social{
      padding-top: 10px;
      clear: both;
      text-align: right;
      a{
        display: inline-block;
        vertical-align: top;
        width: 42px;
        height: 42px;border-radius: 50%;
        color: #ffffff;
        text-align: center;
        &>*{
          line-height: 42px;
        }
        margin-left: 7px;
        &.vk{
          background: #6996c7;
        }
        &.insta{
          background: #7a6b67;
        }
      }
    }

  }
  .row{
    @media(max-width: 575px){
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media(max-width: 991px){
    .footer_logo,.footer_contacts{
      text-align: center;
      flex:0 1 100%;
      flex-basis: 100%;
    }
    .footer_logo{
      a{
        float: none;
        display: inline-block;
        margin:0 0 20px 0;

      }
      .text{
        display: block;
      }
    }
    .footer_contacts{
      .phone,.call_me{
        display: block;
        float: none;
        max-width: 180px;
        margin: 0 auto;
      }
      .social{
        text-align: center;
        a{
          margin: 0 5px;
        }
      }
    }
  }
}
.fifth{
  h2{
    margin-bottom: 40px;
  }
}

.btn_wrapper{
  text-align: center;
  margin-top: 30px;

}
.green-btn{
  outline: none;
  cursor: pointer;
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(157, 192, 46);
  background-image: linear-gradient( 90deg, rgb(119,147,36) 0%, rgb(157,192,46) 100%);
  box-shadow: inset 0px 2px 0px 0px rgba(255, 255, 255, 0.34),inset 0px -2px 0px 0px rgba(255, 255, 255, 0.34);
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 18px;
  padding: 18px 35px;
  border-radius: 30px;
}


//modal
.modal{
  &#modal-call{
   .modal-dialog{
    // height: 420px;
   }
  }
  &#modal-thanks{
    .modal-dialog{
    //  height: 200px;
    }
    h3{
      font-size: 36px;
    }
  }
  .modal-dialog{
    max-width: 570px;
    width:95%;
    text-align: center;
    background: #f6f6f6;
    //position: absolute;
    //left: 0;
    //right: 0;
    //top: 0;
    //bottom: 0;
    //
    //margin: auto;
    .modal-content{
      border-radius: 6px;
      box-shadow: none;
      height: 100%;
    }
    .modal-body{
      padding: 50px 35px 45px;
      text-align: center;
    }
  }
  .modal-title{
    .close{
      background: #48aad3 url("../img/close.png") center no-repeat;
      top: -15px;
      right: -15px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      z-index: 10001;
      opacity: 1;
      cursor: pointer;
      @extend .anim_all_05;
      &:hover{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      @media(max-width: 575px){
        top: 5px;
        right: 5px;
      }
    }
  }
  h3{
    text-align: center;
    font-size: 26px;
   @extend .futura;
    margin: 0 0 20px;
    color: #063e8d;
    text-transform: uppercase;
    span{
      font-size: 35px;
      &.green{
        color: #265b62;
      }
    }
  }
  p{
    text-align: center;
    font-size: 15px;
    margin: 0;
  }
  .form{
.green-btn{
  margin-top: 20px;
}

    form{
      margin: 25px auto 0;
      width: 100%;
      input{
        margin-bottom: 10px;
        line-height: 38px;
        border: 1px solid #d8d8d8;
        background: #fff;
        padding: 0 20px;
        font-size: 15px;
      }
      input,.btn{
        width:100%;
      }

    }
  }
}


.products{
  background: url("../img/grey_bg.png") top left repeat;
  h2{
    margin-bottom: 40px;
    span{
      @extend .futura;
      font-size: 21px;
      color: #333333;
      text-transform: uppercase;
      margin-top: 20px;
      display: block;
    }
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    @media(min-width: 1200px){
      margin-left: -10px;
      margin-right: -10px;
    }
    text-align: center;
    li{
      display: inline-block;
      vertical-align: top;
      margin: 10px 6px;
      width: 280px;
      //height: 550px;
      padding: 20px 15px;
      border: 1px dashed #95a4b0;
      img{
        max-height: 357px;
        width:auto;
      }
    }
    .prod_title{
      color: #9dc02e;
      font-size: 36px;
      line-height: 1;
      @extend .nova;
      text-transform: uppercase;
      text-align: center;
      min-height: 72px;
    }
    button{
      margin-top: 10px;
    }

  }
}
.third,.products_1{
  background: url("../img/slider_bg2.png")top center no-repeat;
  padding-bottom: 110px;
  -webkit-background-size: cover;
  background-size: cover;
  margin-top: -110px;
  position: relative;
  z-index:1;
  padding-top: 130px;
}
.products_2{
  position: relative;
  z-index:1;
  &:after{
    top: 100%;
    left: 0;
    content: '';
    position: absolute;
    background: url("../img/after_line_grey.png")left top no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    height: 82px;
    width: 100%;
  }
  margin-top: -110px;
  padding-top: 65px;
}
.fifth{
  padding-top: 130px;
  padding-bottom: 110px;
}
.water_title{
  display: block;
}
@mixin fontFace($font-name, $font-weight: normal){
  @font-face {
    font-family: "#{$font-name}";
    src: url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.eot");
    src: url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.eot?#iefix")format("embedded-opentype"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.woff") format("woff"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.woff2") format("woff2"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.otf") format("opentype"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.svg") format("SVG");
    font-style: normal;
    font-weight: $font-weight;
  }
}
@mixin fontFace2($font-name, $font-weight){
  @font-face {
    font-family: "#{$font-name}";
    src: url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.eot");
    src: url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.eot?#iefix")format("embedded-opentype"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.woff") format("woff"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.woff2") format("woff2"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.otf") format("opentype"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.svg") format("SVG");
    font-style: normal;
    font-weight: $font-weight;
  }
}

@mixin placeholder($color) {
  ::-webkit-input-placeholder {color:$color;opacity: 1!important;}
  ::-moz-placeholder          {color:$color;opacity: 1!important;}/* Firefox 19+ */
  :-moz-placeholder           {color:$color;opacity: 1!important;}/* Firefox 18- */
  :-ms-input-placeholder      {color:$color;opacity: 1!important;}
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    width: 100%;
    height: 0;
    clear: both;
    opacity: 0;
  }
}
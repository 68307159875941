// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-big-bottle-of-water-name: 'sprite-big-bottle-of-water';
$sprite-big-bottle-of-water-x: 512px;
$sprite-big-bottle-of-water-y: 187px;
$sprite-big-bottle-of-water-offset-x: -512px;
$sprite-big-bottle-of-water-offset-y: -187px;
$sprite-big-bottle-of-water-width: 95px;
$sprite-big-bottle-of-water-height: 120px;
$sprite-big-bottle-of-water-total-width: 759px;
$sprite-big-bottle-of-water-total-height: 606px;
$sprite-big-bottle-of-water-image: '../img/sprite.png';
$sprite-big-bottle-of-water: (512px, 187px, -512px, -187px, 95px, 120px, 759px, 606px, '../img/sprite.png', 'sprite-big-bottle-of-water', );
$sprite-delivery-name: 'sprite-delivery';
$sprite-delivery-x: 512px;
$sprite-delivery-y: 402px;
$sprite-delivery-offset-x: -512px;
$sprite-delivery-offset-y: -402px;
$sprite-delivery-width: 91px;
$sprite-delivery-height: 94px;
$sprite-delivery-total-width: 759px;
$sprite-delivery-total-height: 606px;
$sprite-delivery-image: '../img/sprite.png';
$sprite-delivery: (512px, 402px, -512px, -402px, 91px, 94px, 759px, 606px, '../img/sprite.png', 'sprite-delivery', );
$sprite-ellipse-name: 'sprite-ellipse';
$sprite-ellipse-x: 512px;
$sprite-ellipse-y: 0px;
$sprite-ellipse-offset-x: -512px;
$sprite-ellipse-offset-y: 0px;
$sprite-ellipse-width: 165px;
$sprite-ellipse-height: 187px;
$sprite-ellipse-total-width: 759px;
$sprite-ellipse-total-height: 606px;
$sprite-ellipse-image: '../img/sprite.png';
$sprite-ellipse: (512px, 0px, -512px, 0px, 165px, 187px, 759px, 606px, '../img/sprite.png', 'sprite-ellipse', );
$sprite-factor-1-name: 'sprite-factor_1';
$sprite-factor-1-x: 0px;
$sprite-factor-1-y: 0px;
$sprite-factor-1-offset-x: 0px;
$sprite-factor-1-offset-y: 0px;
$sprite-factor-1-width: 256px;
$sprite-factor-1-height: 256px;
$sprite-factor-1-total-width: 759px;
$sprite-factor-1-total-height: 606px;
$sprite-factor-1-image: '../img/sprite.png';
$sprite-factor-1: (0px, 0px, 0px, 0px, 256px, 256px, 759px, 606px, '../img/sprite.png', 'sprite-factor_1', );
$sprite-factor-2-name: 'sprite-factor_2';
$sprite-factor-2-x: 256px;
$sprite-factor-2-y: 256px;
$sprite-factor-2-offset-x: -256px;
$sprite-factor-2-offset-y: -256px;
$sprite-factor-2-width: 253px;
$sprite-factor-2-height: 254px;
$sprite-factor-2-total-width: 759px;
$sprite-factor-2-total-height: 606px;
$sprite-factor-2-image: '../img/sprite.png';
$sprite-factor-2: (256px, 256px, -256px, -256px, 253px, 254px, 759px, 606px, '../img/sprite.png', 'sprite-factor_2', );
$sprite-factor-3-name: 'sprite-factor_3';
$sprite-factor-3-x: 256px;
$sprite-factor-3-y: 0px;
$sprite-factor-3-offset-x: -256px;
$sprite-factor-3-offset-y: 0px;
$sprite-factor-3-width: 256px;
$sprite-factor-3-height: 256px;
$sprite-factor-3-total-width: 759px;
$sprite-factor-3-total-height: 606px;
$sprite-factor-3-image: '../img/sprite.png';
$sprite-factor-3: (256px, 0px, -256px, 0px, 256px, 256px, 759px, 606px, '../img/sprite.png', 'sprite-factor_3', );
$sprite-factor-4-name: 'sprite-factor_4';
$sprite-factor-4-x: 0px;
$sprite-factor-4-y: 256px;
$sprite-factor-4-offset-x: 0px;
$sprite-factor-4-offset-y: -256px;
$sprite-factor-4-width: 256px;
$sprite-factor-4-height: 256px;
$sprite-factor-4-total-width: 759px;
$sprite-factor-4-total-height: 606px;
$sprite-factor-4-image: '../img/sprite.png';
$sprite-factor-4: (0px, 256px, 0px, -256px, 256px, 256px, 759px, 606px, '../img/sprite.png', 'sprite-factor_4', );
$sprite-flask-name: 'sprite-flask';
$sprite-flask-x: 677px;
$sprite-flask-y: 186px;
$sprite-flask-offset-x: -677px;
$sprite-flask-offset-y: -186px;
$sprite-flask-width: 66px;
$sprite-flask-height: 90px;
$sprite-flask-total-width: 759px;
$sprite-flask-total-height: 606px;
$sprite-flask-image: '../img/sprite.png';
$sprite-flask: (677px, 186px, -677px, -186px, 66px, 90px, 759px, 606px, '../img/sprite.png', 'sprite-flask', );
$sprite-hourglass-name: 'sprite-hourglass';
$sprite-hourglass-x: 677px;
$sprite-hourglass-y: 0px;
$sprite-hourglass-offset-x: -677px;
$sprite-hourglass-offset-y: 0px;
$sprite-hourglass-width: 82px;
$sprite-hourglass-height: 96px;
$sprite-hourglass-total-width: 759px;
$sprite-hourglass-total-height: 606px;
$sprite-hourglass-image: '../img/sprite.png';
$sprite-hourglass: (677px, 0px, -677px, 0px, 82px, 96px, 759px, 606px, '../img/sprite.png', 'sprite-hourglass', );
$sprite-logo-name: 'sprite-logo';
$sprite-logo-x: 173px;
$sprite-logo-y: 512px;
$sprite-logo-offset-x: -173px;
$sprite-logo-offset-y: -512px;
$sprite-logo-width: 173px;
$sprite-logo-height: 94px;
$sprite-logo-total-width: 759px;
$sprite-logo-total-height: 606px;
$sprite-logo-image: '../img/sprite.png';
$sprite-logo: (173px, 512px, -173px, -512px, 173px, 94px, 759px, 606px, '../img/sprite.png', 'sprite-logo', );
$sprite-logo2-name: 'sprite-logo2';
$sprite-logo2-x: 0px;
$sprite-logo2-y: 512px;
$sprite-logo2-offset-x: 0px;
$sprite-logo2-offset-y: -512px;
$sprite-logo2-width: 173px;
$sprite-logo2-height: 94px;
$sprite-logo2-total-width: 759px;
$sprite-logo2-total-height: 606px;
$sprite-logo2-image: '../img/sprite.png';
$sprite-logo2: (0px, 512px, 0px, -512px, 173px, 94px, 759px, 606px, '../img/sprite.png', 'sprite-logo2', );
$sprite-pregnant-name: 'sprite-pregnant';
$sprite-pregnant-x: 677px;
$sprite-pregnant-y: 276px;
$sprite-pregnant-offset-x: -677px;
$sprite-pregnant-offset-y: -276px;
$sprite-pregnant-width: 40px;
$sprite-pregnant-height: 90px;
$sprite-pregnant-total-width: 759px;
$sprite-pregnant-total-height: 606px;
$sprite-pregnant-image: '../img/sprite.png';
$sprite-pregnant: (677px, 276px, -677px, -276px, 40px, 90px, 759px, 606px, '../img/sprite.png', 'sprite-pregnant', );
$sprite-salad-name: 'sprite-salad';
$sprite-salad-x: 436px;
$sprite-salad-y: 512px;
$sprite-salad-offset-x: -436px;
$sprite-salad-offset-y: -512px;
$sprite-salad-width: 90px;
$sprite-salad-height: 80px;
$sprite-salad-total-width: 759px;
$sprite-salad-total-height: 606px;
$sprite-salad-image: '../img/sprite.png';
$sprite-salad: (436px, 512px, -436px, -512px, 90px, 80px, 759px, 606px, '../img/sprite.png', 'sprite-salad', );
$sprite-slim-body-with-timer-name: 'sprite-slim-body-with-timer';
$sprite-slim-body-with-timer-x: 677px;
$sprite-slim-body-with-timer-y: 96px;
$sprite-slim-body-with-timer-offset-x: -677px;
$sprite-slim-body-with-timer-offset-y: -96px;
$sprite-slim-body-with-timer-width: 78px;
$sprite-slim-body-with-timer-height: 90px;
$sprite-slim-body-with-timer-total-width: 759px;
$sprite-slim-body-with-timer-total-height: 606px;
$sprite-slim-body-with-timer-image: '../img/sprite.png';
$sprite-slim-body-with-timer: (677px, 96px, -677px, -96px, 78px, 90px, 759px, 606px, '../img/sprite.png', 'sprite-slim-body-with-timer', );
$sprite-speed-name: 'sprite-speed';
$sprite-speed-x: 346px;
$sprite-speed-y: 512px;
$sprite-speed-offset-x: -346px;
$sprite-speed-offset-y: -512px;
$sprite-speed-width: 90px;
$sprite-speed-height: 90px;
$sprite-speed-total-width: 759px;
$sprite-speed-total-height: 606px;
$sprite-speed-image: '../img/sprite.png';
$sprite-speed: (346px, 512px, -346px, -512px, 90px, 90px, 759px, 606px, '../img/sprite.png', 'sprite-speed', );
$sprite-work-name: 'sprite-work';
$sprite-work-x: 512px;
$sprite-work-y: 307px;
$sprite-work-offset-x: -512px;
$sprite-work-offset-y: -307px;
$sprite-work-width: 95px;
$sprite-work-height: 95px;
$sprite-work-total-width: 759px;
$sprite-work-total-height: 606px;
$sprite-work-image: '../img/sprite.png';
$sprite-work: (512px, 307px, -512px, -307px, 95px, 95px, 759px, 606px, '../img/sprite.png', 'sprite-work', );
$spritesheet-width: 759px;
$spritesheet-height: 606px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($sprite-big-bottle-of-water, $sprite-delivery, $sprite-ellipse, $sprite-factor-1, $sprite-factor-2, $sprite-factor-3, $sprite-factor-4, $sprite-flask, $sprite-hourglass, $sprite-logo, $sprite-logo2, $sprite-pregnant, $sprite-salad, $sprite-slim-body-with-timer, $sprite-speed, $sprite-work, );
$spritesheet: (759px, 606px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
